import { SelectiveBloomEffect } from 'postprocessing';
import { inject, nextTick, provide, ref, shallowRef, watch, watchEffect } from 'vue';

import { usePane, useViewportResize } from '@resn/gozer-vue';

export const useBloom = () => {
    const bloom = inject('bloom', {});
    if (!bloom.init) {
        console.warn('useBloom must be used within a BloomProvider');
        return { pass: null };
    }
    return bloom;
};

export const BLOOM_INTENSITY = 2;

export const useBloomContext = () => {
    const pass = shallowRef(null);
    const luminanceThreshold = ref(0.0);
    const luminanceSmoothing = ref(0.025);
    const intensity = ref(BLOOM_INTENSITY);
    const radius = ref(0.6);
    const levels = ref(5);
    const mipmapBlur = ref(true);
    const scale = ref(1);

    const init = (scene, camera) => {
        pass.value = new SelectiveBloomEffect(scene, camera, {
            luminanceThreshold: luminanceThreshold.value,
            luminanceSmoothing: luminanceSmoothing.value,
            intensity: intensity.value,
            radius: radius.value,
            levels: levels.value,
            mipmapBlur: mipmapBlur.value,
        });
        pass.value.ignoreBackground = true;

        setProps();

        return pass.value;
    };

    const setProps = () => {
        if (pass.value) {
            pass.value.intensity = intensity.value;
            pass.value.luminanceMaterial.threshold = luminanceThreshold.value;
            pass.value.luminanceMaterial.smoothing = luminanceSmoothing.value;
            pass.value.mipmapBlurPass.enabled = mipmapBlur.value;
            pass.value.mipmapBlurPass.radius = radius.value;
            pass.value.mipmapBlurPass.levels = levels.value;
        }
    };

    watchEffect(setProps);

    const resize = () => {
        nextTick(() => {
            const { width, height } = viewport;
            if (pass.value) pass.value.setSize(width * scale.value, height * scale.value);
        });
    };

    const viewport = useViewportResize(resize, true);

    watch([scale, pass], resize);

    const data = { init, pass, luminanceThreshold, intensity, radius };

    provide('bloom', data);

    usePane(
        [
            {
                name: 'luminanceThreshold',
                value: luminanceThreshold,
                options: { min: 0, max: 1, step: 0.01 },
            },
            { name: 'intensity', value: intensity, options: { min: 0, max: 10, step: 0.01 } },
            { name: 'radius', value: radius, options: { min: 0, max: 5, step: 0.01 } },
            { name: 'levels', value: levels, options: { min: 1, max: 20, step: 1 } },
            { name: 'scale', value: scale, options: { min: 0, max: 1, step: 0.01 } },
        ],
        { title: 'Bloom', subfolder: false, expanded: false }
    );

    return data;
};
