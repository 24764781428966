import {
    EffectComposer,
    EffectPass,
    FXAAEffect,
    RenderPass,
    Selection,
    ToneMappingEffect,
    ToneMappingMode,
} from 'postprocessing';
import { ref, watch } from 'vue';

import { usePane, useViewportResize } from '@resn/gozer-vue';

import { useBloomContext } from '~/composables/useBloom';
import { LAYER_BG, LAYER_FG } from '~/core/constants';

export const useSceneComposerPmndrs = ({ renderer = null, bloom = true, fxaa = true } = {}) => {
    const _composer = new EffectComposer(renderer);
    _composer.autoRenderToScreen = true;

    const type = 'pmndrs';
    const bloomContext = useBloomContext();

    const setup = (renderer, scene, camera, orthoCamera) => {
        const passRenderBg = new RenderPass(scene, orthoCamera);
        passRenderBg.selection = new Selection([], LAYER_BG);
        passRenderBg.name = 'RenderPassBG';

        const passRenderScene = new RenderPass(scene, camera);
        passRenderScene.clearPass.setClearFlags(false, true, false);
        passRenderScene.name = 'RenderPassScene';

        /*         const passRenderUI = new RenderPass(scene, orthoCamera);
        passRenderUI.selection = new Selection([], LAYER_FG);
        passRenderUI.clearPass.setClearFlags(false, true, false) */

        const fxaaEffect = new FXAAEffect();
        const bloomEffect = bloomContext.init(scene, camera);
        const toneMappingEffect = new ToneMappingEffect({ mode: ToneMappingMode.NEUTRAL });

        const passEffectsBloom = new EffectPass(scene, bloomEffect);
        passEffectsBloom.name = 'PassEffectsBloom';

        const passEffectsAA = new EffectPass(orthoCamera, fxaaEffect);
        passEffectsAA.name = 'PassEffectsAA';

        const passEffectTonemapping = new EffectPass(orthoCamera, toneMappingEffect);
        passEffectTonemapping.name = 'PassEffectTonemapping';

        _composer.addPass(passRenderBg);
        _composer.addPass(passRenderScene);

        if (fxaa) _composer.addPass(passEffectsAA);
        if (bloom) _composer.addPass(passEffectsBloom);

        // _composer.addPass(passRenderUI);
        _composer.addPass(passEffectTonemapping);

        resize(viewport);
    };

    const render = () => {
        _composer?.render();
    };

    const resize = ({ width, height }) => {
        _composer?.setSize(width, height);
    };

    const viewport = useViewportResize(resize, true);

    const fxaaEnabled = ref(true);
    const bloomEnabled = ref(true);

    watch(fxaaEnabled, (val) => {
        _composer.passes.find((pass) => pass.name === 'PassEffectsAA').enabled = val;
    });

    watch(bloomEnabled, (val) => {
        _composer.passes.find((pass) => pass.name === 'PassEffectsBloom').enabled = val;
    });
    usePane(
        [
            fxaa ? { name: 'fxaaEnabled', value: fxaaEnabled } : null,
            bloom ? { name: 'bloomEnabled', value: bloomEnabled } : null,
        ],
        { title: 'Composer', subfolder: false, expanded: false }
    );

    return {
        get composer() {
            return _composer;
        },
        setup,
        render,
        type,
    };
};
