import { noop } from '@vueuse/core';
import { RepeatWrapping, SRGBColorSpace, Vector3 } from 'three';
import { defineComponent, inject, onMounted, provide } from 'vue';

import { LoaderEvent } from '@resn/gozer-loading';
import { useLoaderComplete } from '@resn/gozer-vue/loading';

export const GlobalAssetsKey = Symbol('GlobalAssets');

export const useGlobalAssets = (onLoaded = noop) => {
    const provider = inject(GlobalAssetsKey);

    if (!provider) {
        throw new Error('GlobalAssets provider not found');
    }

    onMounted(() => {
        if (provider.loader.state === 'complete') onLoaded({ data: provider.loader.data });
        provider.loader.once(LoaderEvent.LOAD_COMPLETE, onLoaded);
    });

    return provider;
};

export const GlobalAssets = defineComponent({
    props: {},

    async setup() {
        const onLoaded = ({ data }) => {
            provider.assets = data;

            Object.values(data).forEach((asset) => {
                if (asset.isTexture) {
                    asset.colorSpace = SRGBColorSpace;
                    asset.wrapS = asset.wrapT = RepeatWrapping;
                    asset.flipY = false;
                    asset.needsUpdate = true;
                }
            });
        };
        onMounted(() => {
            window.tempVec = new Vector3();
        });

        const loader = useLoaderComplete(
            {
                // envMap: '/textures/env-map/env-map-6-etc1s.ktx2',
                envMap: '/textures/env-map/env-map-6@lg.webp#texture',
                matcapDiffuse: '/textures/matcap/slab-diffuse-etc1s.ktx2',
                // matcapDiffuse: '/textures/matcap/slab-diffuse-noise-etc1s.ktx2',

                // Card textures
                cardMask: '/textures/card/card-mask-etc1s.ktx2',
                cardMaskInverse: '/textures/card/card-mask-inverse-etc1s.ktx2',
                cardOutline: '/textures/card/outline_mask-etc1s.ktx2',
                cardNormal: '/textures/card/normal-map-etc1s.ktx2',
                // card: '/glb/slab-uv-etc1s@lg.glb',
                card: '/glb/slab-uv2-etc1s@lg.glb',

                noiseWhite: '/textures/noise/white-noise-etc1s.ktx2',
                // noise1: '/textures/noise/noise-etc1s.ktx2',
                noise1: '/textures/noise/noise.png#texture',
                matcapRevised6: '/textures/matcap/matcap-revised_6-etc1s.ktx2',
                pNoise: '/textures/noise/pnoise1-etc1s.ktx2',
                pNoise2: '/textures/noise/pnoise2-etc1s.ktx2',
                pnoise3: '/textures/noise/pnoise3-etc1s.ktx2',
            },
            onLoaded
        );

        const provider = {
            assets: null,
            loader,
        };

        provide(GlobalAssetsKey, provider);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }
        return null;
    },
});
