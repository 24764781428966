import {
    AxesHelper,
    BoxGeometry,
    CameraHelper,
    DirectionalLightHelper,
    GridHelper,
    Group,
    Mesh,
    MeshBasicMaterial,
    PointLightHelper,
} from 'three';

export const useThreeHelpers = (scene) => {
    const helpersGroup = new Group();

    const axesHelper = new AxesHelper(1);
    const gridHelper = new GridHelper(100, 100);

    const meshRef = new Mesh(
        new BoxGeometry(0.05, 0.05, 0.05),
        new MeshBasicMaterial({ color: 0xff0000 })
    );

    helpersGroup.add(axesHelper);
    helpersGroup.add(gridHelper);
    helpersGroup.add(meshRef);

    if (scene) scene.add(helpersGroup);

    const helpersTypes = ['CameraHelper', 'PointLightHelper', 'DirectionalLightHelper'];

    const addCameraHelpers = () => {
        scene.traverseVisible((obj) => {
            if (obj.type === 'PerspectiveCamera' || obj.type === 'OrthographicCamera')
                helpersGroup.add(new CameraHelper(obj));
        });
    };

    const addLightsHelpers = () => {
        scene.traverseVisible((obj) => {
            if (obj.type === 'PointLight') helpersGroup.add(new PointLightHelper(obj, 0.5));
            if (obj.type === 'DirectionalLight')
                helpersGroup.add(new DirectionalLightHelper(obj, 0.5));
        });
    };

    const disposeHelpers = () => {
        const helpersToDispose = [];
        helpersGroup.traverse((obj) => {
            if (obj === helpersGroup) return;
            if (!helpersTypes.includes(obj.type)) return;
            helpersToDispose.push(obj);
        });
        helpersToDispose.forEach((obj) => helpersGroup.remove(obj));
    };

    const toggleHelpers = (val) => {
        helpersGroup.visible = val === undefined ? !helpersGroup.visible : val;
    };

    const refreshHelpers = () => {
        disposeHelpers();

        addCameraHelpers();
        addLightsHelpers();
    };

    return {
        group: helpersGroup,
        addCameraHelpers,
        addLightsHelpers,
        refreshHelpers,
        toggleHelpers,
    };
};
